<template>
  <div id="app2022settings">
    <router-view ref="seosettings" name="seosettings"></router-view>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'AppSeo',
  components: {},
  methods: {
    async init() {

    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="../public/css/awesome.min.css" lang="css"></style>
<style scoped>
body{
  margin: 0;
}
#app2022settings {

}
</style>