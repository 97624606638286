/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import AppSeo from './AppSeo.vue'
import VueRouter from 'vue-router'
import { routes } from './routes'


Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(require('vue-cookies'))

const router = new VueRouter({
	routes,
	mode: 'history',
    stringifyQuery(obj){
		let keys = Object.keys(obj);
		if(keys.length>0){
			let str = [];
            for (let p in obj){
                if (obj.hasOwnProperty(p)) {
                    if(p=='url' && obj.url && obj.url.indexOf('?')==-1 && obj.url.indexOf('&')==-1 ){
                        str.push('url='+obj[p]);
					}
                    else str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
			}
            //console.log('?'+str.join("&"));
            return '?'+str.join("&").replace('//','///');
		}
		return '';
	}
});
Date.prototype.fdate = function() {
	var mm = this.getMonth() + 1;
	var dd = this.getDate();

	return [this.getFullYear(), '-',
		(mm>9 ? '' : '0') + mm, '-',
		(dd>9 ? '' : '0') + dd
	].join('');
};
window.mainvue = new Vue({
	data() {
		return {
			ll:{},
			lang:window.lang_global || "en",
			languages:["en","de","es","fr","it","nl","pt","zh","tr","ru"],
			prod:typeof semaltprod!='undefined' && semaltprod.prod ? true: false,
			user:{
				id:null
			}
		}
	},
  render: h => h(App),
  router
}).$mount('#appsemalt');

new Vue({
	data() {
		return {
			ll:{},
			lang:window.lang_global || "en",
			languages:["en","de","es","fr","it","nl","pt","zh","tr","ru"],
			prod:typeof semaltprod!='undefined' && semaltprod.prod ? true: false,
			user:{
				id:null
			}
		}
	},
	render: h => h(AppSeo),
	router
}).$mount('#appseosettings');

//this.$children[0].$refs.rView
