<template>
  <div id="app">
    <!--<router-view ref="seosettings" name="seosettings"></router-view>-->
    <router-view ref="rView" />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'app',
  components: {},
  methods: {
    async init() {

    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="../public/css/awesome.min.css" lang="css"></style>
<style>
body{
  margin: 0;
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}
</style>
