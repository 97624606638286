/* eslint-disable */
/* import HelloWorld from './components/HelloWorld.vue' */

const HelloWorld = () => import('./components/HelloWorld.vue');
const ClientTestimonials = () => import('./pages/ClientTestimonials.vue');
const Wechat = () => import('./pages/Wechat.vue');
const Project = () => import('./pages/Project.vue');
const WriteReview = () => import('./pages/WriteReview.vue');
const BacklinksAgencies = () => import('./pages/BacklinksAgencies.vue');
const DedicatedSemaltDashboard = () => import('./pages/DedicatedSemaltDashboard.vue');
const autoseoTrial = () => import('./pages/autoseoTrial.vue');
const seoConsultation = () => import('./pages/seoConsultation.vue');
const pageSpeed = () => import('./pages/pageSpeed.vue');
const pagesSpeed = () => import('./pages/pagesSpeed.vue');
const DedicatedSeoDashboard = () => import('./pages/DedicatedSeoDashboard.vue');
const SeoSettings = () => import('./components/SeoSettings.vue');

export const routes = [
	{
		name: 'index',
		path: '/',
		component: HelloWorld
	},
	{
		name: 'clienttestimonials',
		path: '/client-testimonials',
		component: ClientTestimonials
	},
	{
		name: 'clienttestimonialsLang',
		path: '/:lang/client-testimonials',
		component: ClientTestimonials
	},
	{
		name: 'BacklinkAgencies',
		path: '/backlinks-for-seo-agencies',
		component: BacklinksAgencies
	},
	{
		name: 'BacklinkAgenciesLang',
		path: '/:lang/backlinks-for-seo-agencies',
		component: BacklinksAgencies
	},
	{
		name: 'wechat',
		path: '/wechat',
		component: Wechat
	},
	{
		name: 'wechatLang',
		path: '/:lang/wechat',
		component: Wechat
	},
	{
		name: 'project',
		path: '/project/:idproject',
		components: {
			default: Project,
			seosettings: SeoSettings
		}
	},
    {
        name: 'sproject',
        path: '/sproject/:idproject',
        components: {
            default: Project,
            seosettings: SeoSettings
        }
    },
	{
		name: 'fproject',
		path: '/fproject/:idproject',
		component: Project
	},
	{
		name: 'WriteReview',
		path: '/write-a-review',
		component: WriteReview
	},
    {
        name: 'DedicatedSemaltDashboard',
        path: '/dedicated-semalt-dashboard',
        //component: DedicatedSemaltDashboard
        redirect: { name: 'DedicatedSeoDashboard' }
    },
    {
        name: 'DedicatedSemaltDashboardLang',
        path: '/:lang/dedicated-semalt-dashboard',
        //component: DedicatedSemaltDashboard
        redirect: { name: 'DedicatedSeoDashboard' }
    },
    {
        name: 'autoseoTrial',
        path: '/autoseo-trial',
        component: autoseoTrial
    },
    {
        name: 'autoseoTrialLang',
        path: '/:lang/autoseo-trial',
        component: autoseoTrial
    },
    {
        name: 'seoConsultation',
        path: '/seo-consultation',
        component: seoConsultation
    },
    {
        name: 'seoConsultationLang',
        path: '/:lang/seo-consultation',
        component: seoConsultation
    },
    {
        name: 'pageSpeed',
        path: '/page-speed',
        component: pageSpeed
    },
    {
        name: 'pageSpeedLang',
        path: '/:lang/page-speed',
        component: pageSpeed
    },
    {
        name: 'pagesSpeed',
        path: '/pages-speed',
        component: pagesSpeed
    },
    {
        name: 'pagesSpeedLang',
        path: '/:lang/pages-speed',
        component: pagesSpeed
    },
    {
        name: 'DedicatedSeoDashboard',
        path: '/dedicated-seo-dashboard',
        component: DedicatedSeoDashboard
    },
    {
        name: 'DedicatedSeoDashboardLang',
        path: '/:lang/dedicated-seo-dashboard',
        component: DedicatedSeoDashboard
    },
	{
		path: '*',
		component: seoConsultation
		//redirect: { name: 'index' }
	}
];
